import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface BannerDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const StyledBannerDetail = styled.div`
  background-color: ${({ theme }) => theme.color.dark};
  padding: 50px 0;
  margin: 0 0 100px 0;
  height: 385px;

  @media screen and (max-width: 1199px) {
    margin: 0 0 80px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 0;
    margin: 0 0 80px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0;
    margin: 0;
    height: 340px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 50px;
    line-height: 50px;
    color: ${({ theme }) => theme.color.light};
    position: relative;
    padding-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  @media screen and (max-width: 991px) {
    & h1,
    & h2 {
      font-size: 40px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 575px) {
    & h1,
    & h2 {
      font-size: 30px;
      line-height: 30px;
    }
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  left: 0;
  top: 50px;
  max-height: 288px;

  @media screen and (max-width: 1199px) {
    top: 30px;
    max-height: 240px;
  }

  @media screen and (max-width: 991px) {
    top: 0;
    position: relative !important;
    margin-top: 30px;
  }

  @media screen and (max-width: 575px) {
    margin-top: 15px;
  }
`

const BannerDetail: React.FC<BannerDetailProps> = ({ fields }) => (
  <StyledBannerDetail>
    <div className="container">
      <Content content={fields.description || ''} />
      <div className="row justify-content-center position-relative">
        <div className="col-lg-8 position-absolute">
          <StyledPlaatjie image={fields.image} alt="" />
        </div>
      </div>
    </div>
  </StyledBannerDetail>
)

export default BannerDetail

/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React from 'react'

// Images
import Next from 'img/next.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

import 'swiper/css'

SwiperCore.use([Navigation])

interface BannerGalleryProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerGallery = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
  padding: 50px 0;

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0;
  }
`

const Title = styled(ParseContent)`
  & h3 {
    font-size: 30px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.light};
  }
`

const Gallery = styled.div`
  margin: 0 -300px 0 -30px;
  position: relative;

  @media screen and (max-width: 991px) {
    margin: 0 -100px 0 -15px;
  }

  @media screen and (max-width: 575px) {
    margin: 0 -100px 0 0;
  }
`

const ImageWrapper = styled.div`
  height: 245px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 991px) {
    height: 170px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
`

const GalleryGradient = styled.div`
  background: linear-gradient(
    270.69deg,
    #333333 16.25%,
    rgba(51, 51, 51, 0.77) 58.66%,
    rgba(51, 51, 51, 0) 99.34%
  );
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  height: 100%;
  width: 510px;
  right: 0px;
  top: 0;
  z-index: 5;

  @media screen and (max-width: 991px) {
    width: 260px;
  }
`

const NextSlide = styled.div`
  position: absolute;
  z-index: 6;
  top: 15px;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  right: 0px;
  height: 55px;
  width: 55px;
  cursor: pointer;

  @media screen and (max-width: 575px) {
    right: 15px;
  }

  @media screen and (max-width: 400px) {
    width: 30px;
    height: 30px;
    top: 60px;

    & svg {
      width: 30px;
      height: 30px;
    }
  }
`

const BannerGallery: React.FC<BannerGalleryProps> = ({ fields }) => {
  const prevRef = React.useRef<HTMLDivElement>(null)
  const nextRef = React.useRef<HTMLDivElement>(null)
  const [swiper, setSwiper] = React.useState<any>(null)

  return (
    <StyledBannerGallery>
      <div className="container position-relative">
        <NextSlide onClick={() => swiper?.slideNext(500)} ref={nextRef}>
          <Next />
        </NextSlide>
        <Title content={fields.description || ''} />
        <Gallery className="py-lg-5 py-3">
          <GalleryGradient />

          <Swiper
            spaceBetween={10}
            onInit={(s: any) => {
              setSwiper(s)
            }}
            loop
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            breakpoints={{
              575: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            slidesPerView={2}
          >
            {fields.images?.map((image, index) => (
              <SwiperSlide key={index}>
                <ImageWrapper>
                  <StyledPlaatjie image={image?.image} alt="" />
                </ImageWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </Gallery>
      </div>
    </StyledBannerGallery>
  )
}

export default BannerGallery

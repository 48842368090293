import React from 'react'

// Images
import AxignLogo from 'img/Axign-X-compleet.inline.svg'
import axigndot from 'img/axign-dot.png'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 0;
  margin: 0 0 0 0;
  overflow: hidden;
  position: relative;
`

const StyledPlaatjie = styled(Plaatjie) <{ image: any }>`
  position: absolute !important;
  height: 100%;
  width: 1000px;
  top: 0;
  left: 0;
  z-index: 2;

  & img {
    object-fit: cover !important;
  }
`

const Content = styled(ParseContent)`
  padding: 120px 0 120px 0;
  color: ${(props) => props.theme.color.dark};
  position: relative;
  z-index: 4;

  @media screen and (min-width: 992px){
    & h1 {
      font-size: 50px;
    }

    & p {
      font-size: 18px;
    }
  }

  & .axign-home-dots {
    & li {
      position: relative;
      list-style: none;
      font-size: 18px;
      line-height: 40px;

      &:before {
        content: '';
        position: absolute;
        left: -30px;
        top: 10px;
        background-image: url(${axigndot});
        background-size: contain;
        background-repeat: no-repeat;
        height: 20px;
        width: 20px;
      }
    }

    @media (max-width: 575px) {
      & li {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 80px 0 80px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 60px 0 60px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 165px 0 15px 0;

    & h1,
    h2 {
      margin-bottom: 10px;
      font-size: 40px;
      line-height: 40px;
      padding-bottom: 0;
    }
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  left: 150px;
  height: 100%;
  width: 1000px;

  & svg {
    position: relative;
    top: -120px;
    left: -1px;
    z-index: 3;
  }

  @media screen and (max-width: 1199px) {
    left: -100px;
  }

  @media screen and (max-width: 991px) {
    top: 0;
    left: 400px;

    & svg {
      top: -170px;
    }
  }

  @media screen and (max-width: 575px) {
    height: 150px;
    width: 100%;
    left: 0;
    top: 0;

    & svg {
      display: none;
    }
  }
`

const ImageColumn = styled.div`
  position: relative;

  @media screen and (max-width: 991px) {
    position: unset;
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => (
  <StyledBannerHome>
    <BannerWrapper>
      <div className="container">
        <div className="row position-relative">
          <div className="col-lg-8 col-sm-9 d-flex align-items-center">
            <div className="pe-lg-5 me-lg-5">
              <Content content={fields.description || ''} />
            </div>
          </div>
          <ImageColumn className="col-lg-4">
            <SvgWrapper>
              <AxignLogo />
              <StyledPlaatjie
                loading="eager"
                alt="banner"
                image={fields?.image}
              />
            </SvgWrapper>
          </ImageColumn>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerHome>
)

export default BannerHome

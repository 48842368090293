/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled, { css } from 'styled-components'

// Images
import ArrowDown from 'img/arrow-down.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import { wrap } from 'popmotion'
import { AnimatePresence, motion } from 'framer-motion'

interface BannerApplicationsPreviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerApplicationsPreview = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 100px 0;

  @media screen and (max-width: 1199px) {
    padding: 50px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  pointer-events: none;

  & img {
    object-fit: contain !important;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 50px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & p {
    font-size: 16px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const Title = styled(ParseContent)<{ active: boolean }>`
  & p {
    font-size: 20px;
    line-height: 40px;
    transition: all 0.3s ease;

    ${({ active, theme }) =>
      active
        ? css`
            color: ${theme.color.primary};
            font-weight: ${theme.font.weight.bold};
          `
        : css`
            color: ${theme.color.light};
            font-weight: ${theme.font.weight.light};
          `}

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`

const ContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
}

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 564px;

  @media screen and (max-width: 1199px) {
    height: 624px;
  }

  @media screen and (max-width: 991px) {
    height: 80%;
    top: 10%;
    opacity: 0.1;
  }
`

const ImageContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
`

const ImageCol = styled.div`
  position: relative;

  @media screen and (max-width: 991px) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
`

const ContentCol = styled.div`
  position: relative;
  z-index: 2;
`

const SvgWrapper = styled.div``

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) =>
  Math.abs(offset) * velocity

const BannerApplicationsPreview: React.FC<BannerApplicationsPreviewProps> = ({
  fields,
}) => {
  const [[page, direction], setPage] = React.useState([0, 0])

  const imageIndex = wrap(0, fields.applications?.length || 0, page)

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  return (
    <StyledBannerApplicationsPreview>
      <BannerWrapper>
        <div className="container">
          <div className="row">
            <ContentCol className="col-lg-6">
              <Content content={fields.description || ''} />
              <div className="pt-lg-4 pt-0">
                {fields.applications?.map((application, index) => (
                  <div
                    tabIndex={-1}
                    role="button"
                    onClick={() => paginate(index - imageIndex)}
                    key={index}
                  >
                    <div className="d-flex align-items-center pt-lg-1 pt-3">
                      <Title
                        active={index === imageIndex}
                        content={application?.title || ''}
                      />
                      {index !== imageIndex && (
                        <SvgWrapper className="ms-2">
                          <ArrowDown />
                        </SvgWrapper>
                      )}
                    </div>

                    <ContentWrapper>
                      <AnimatePresence>
                        {index === imageIndex && (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{
                              height: 'min-content',
                            }}
                            exit={{ height: 0 }}
                          >
                            <div className="pb-lg-4 pe-xl-5 me-lg-5">
                              <Content
                                content={application?.description || ''}
                              />
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </ContentWrapper>
                  </div>
                ))}
              </div>
            </ContentCol>
            <ImageCol className="col-lg-6 d-flex justify-content-center">
              <ImageWrapper>
                <AnimatePresence initial={false} custom={direction}>
                  <ImageContainer
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 },
                    }}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDragEnd={(e: any, { offset, velocity }: any) => {
                      const swipe = swipePower(offset.x, velocity.x)

                      if (swipe < -swipeConfidenceThreshold) {
                        paginate(1)
                      } else if (swipe > swipeConfidenceThreshold) {
                        paginate(-1)
                      }
                    }}
                    key={`application-image-${imageIndex}`}
                  >
                    <StyledPlaatjie
                      loading="eager"
                      image={fields.applications![imageIndex]?.image}
                      alt=""
                    />
                  </ImageContainer>
                </AnimatePresence>
              </ImageWrapper>
            </ImageCol>
          </div>
        </div>
      </BannerWrapper>
    </StyledBannerApplicationsPreview>
  )
}

export default BannerApplicationsPreview

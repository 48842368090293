import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

interface BannerQuoteProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerQuote = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 100px 0 100px 0;

  @media screen and (max-width: 1199px) {
    padding: 50px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 30px 0;
  }
`

const Quote = styled(ParseContent)`
  position: relative;
  padding: 0 80px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 25px;
    height: 100%;
    width: 3px;
    background-color: ${({ theme }) => theme.color.primary};
  }

  & blockquote {
    & p {
      font-size: 50px;
      line-height: 60px;

      & b,
      strong {
        font-weight: ${({ theme }) => theme.font.weight.medium};
      }
    }
  }

  & h4 {
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.primary};
  }

  @media screen and (max-width: 1199px) {
    padding: 0 40px;

    &:before {
      left: 10px;
    }

    & blockquote {
      & p {
        font-size: 34px;
        line-height: 40px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    padding: 0 20px;

    &:before {
      left: 0;
    }
  }

  @media screen and (max-width: 400px) {
    & blockquote {
      & p {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }
`

const BannerQuote: React.FC<BannerQuoteProps> = ({ fields }) => (
  <StyledBannerQuote>
    <BannerWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Plaatjie image={fields.image} alt="" />
          </div>
          <div className="col-lg-6 pt-lg-0 pt-4 d-flex align-items-center">
            <Quote content={fields.description || ''} />
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerQuote>
)

export default BannerQuote

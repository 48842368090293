import React from 'react'

// Images
import AxignLogo from 'img/Axign-X-compleet.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'

interface BannerFullImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerFullImage = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 0;
  margin: 0 0 0 0;
  overflow: hidden;
  position: relative;
`

const Content = styled(ParseContent)`
  padding: 100px 0 100px 0;
  color: ${(props) => props.theme.color.dark};
  position: relative;
  z-index: 4;

  & h1,
  h2 {
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 50px;
    line-height: 50px;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 40px;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & p {
    font-size: 16px;
    line-height: 30px;
  }

  @media screen and (max-width: 1199px) {
    padding: 80px 0 80px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 60px 0 60px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 15px 0 15px 0;

    & h1,
    h2 {
      margin-bottom: 10px;
      font-size: 40px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 350px) {
    & h1,
    h2 {
      font-size: 30px;
      line-height: 30px;
    }
  }
`

const ImageColumn = styled.div`
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  height: auto;
  min-height: 300px;

  @media screen and (max-width: 991px) {
    padding: 0 0 60px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 0 0 15px 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: relative !important;

  & img {
    object-fit: cover !important;
  }

  @media screen and (max-width: 575px) {
    width: 500px;
  }
`

const BannerFullImage: React.FC<BannerFullImageProps> = ({ fields }) => (
  <StyledBannerFullImage>
    <BannerWrapper>
      <div className="container">
        <div className="row position-relative">
          <div className="col-xl-6 col-lg-6 col-sm-12 d-flex align-items-center">
            <div className="me-lg-5">
              <Content content={fields.description || ''} />
            </div>
          </div>
          <ImageColumn className="col-xl-6 col-lg-4">
            <StyledPlaatjie
              loading="eager"
              alt="banner"
              image={fields?.image}
            />
          </ImageColumn>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerFullImage>
)

export default BannerFullImage

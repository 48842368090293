/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'
import ButtonOutline from 'components/elements/Buttons/BannerOutline'

// Third Party
import styled from 'styled-components'

interface BannerBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerBlocks = styled.section`
  background: ${({ theme }) => theme.gradient.primaryLinear};
`

const BannerWrapper = styled.div`
  position: relative;
  padding: 50px 0 50px 0;

  @media screen and (max-width: 1199px) {
    padding: 30px 0 30px 0;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & p {
    font-size: 16px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  & h3 {
    font-size: 25px;
    line-height: 30px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  @media screen and (max-width: 1199px) {
    & p {
      padding: 0 260px;
      text-align: center;
    }
  }

  @media screen and (max-width: 991px) {
    & p {
      padding: 0 140px;
    }
  }

  @media screen and (max-width: 575px) {
    & p {
      padding: 0 40px;
    }
  }

  @media screen and (max-width: 400px) {
    & p {
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 350px) {
    & p {
      padding: 0;
    }
  }
`

const Block = styled.div`
  padding: 20px 70px 20px 70px;

  @media screen and (max-width: 1199px) {
    padding: 15px;
  }
`

const SvgWrapper = styled.div`
  & img {
    object-fit: contain;
    max-height: 68px;
    max-width: 58px;
  }
`

const BannerBlocks: React.FC<BannerBlocksProps> = ({ fields }) => (
  <StyledBannerBlocks>
    <BannerWrapper className="container">
      <div className="row">
        {fields.texts?.map((text, index) => (
          <div className="col-xl col-12" key={index}>
            <Block>
              <div className="d-flex justify-content-center align-items-center pb-xl-4 pb-2">
                <SvgWrapper className="pe-3">
                  <Plaatjie image={text?.icon} alt="" />
                </SvgWrapper>
                <Content content={text?.title || ''} />
              </div>
              <Content content={text?.description || ''} />
            </Block>
          </div>
        ))}
      </div>
      <div className="pt-lg-4 d-flex justify-content-center">
        <ButtonOutline to={fields.link?.url || '/'}>
          {fields.link?.title}
        </ButtonOutline>
      </div>
    </BannerWrapper>
  </StyledBannerBlocks>
)

export default BannerBlocks

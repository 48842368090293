import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface BannerTextSmallProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Banner
}

const StyledBannerTextSmall = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
  padding: 15px 0;
`

const Content = styled(ParseContent)`
  & h2 {
    font-size: 50px;
    line-height: 75px;
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media screen and (max-width: 575px) {
    & h2 {
      font-size: 26px;
      line-height: 35px;
    }
  }
`

const BannerTextSmall: React.FC<BannerTextSmallProps> = ({ fields }) => (
  <StyledBannerTextSmall>
    <div className="container">
      <Content content={fields.description || ''} />
    </div>
  </StyledBannerTextSmall>
)

export default BannerTextSmall
